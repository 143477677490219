import httpUtil from "@/utils/httpUtil";

/**字典 */
export const getByDictType = async params => httpUtil.post("/api/crmPc/sysDict/getByDictType", params);
/**获取类型商品列表 */
export const getGoodsInfoPCShop = async params => httpUtil.post("/api/crmPc/companyGoods/getGoodsInfoPCShop", params);


/**添加修改头部背景颜色 */
export const addColorValue = async params => httpUtil.post("/api/crmPc/companyGoods/addColorValue", params);


/**查询头部背景颜色 */
export const queryColorValue = async params => httpUtil.post("/api/crmPc/companyGoods/queryColorValue", params);

/**查询公司信息 */
export const getCompany = async params => httpUtil.post("/api/crmPc/companyGoods/getCompany", params);

/**设置广告 */
export const setAdvertModel = async params => httpUtil.post("/api/crmPc/home/setAdvertModel", params);

/**获取广告列表 */
export const getAdvertModelList = async params => httpUtil.post("/api/crmPc/home/getAdvertModelList", params);

/**设置广告与小程序关联 */
export const setAdvertLink = async params => httpUtil.post("/api/crmPc/home/setAdvertLink", params);

/**获得广告与小程序关联列表 */
export const getAdvertLinkList = async params => httpUtil.post("/api/crmPc/home/getAdvertLinkList", params);

/**获得广告与小程序关联列表 */
export const delAdvertLinkList = async params => httpUtil.post("/api/crmPc/home/delAdvertLinkList", params);